import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const refreshAppServices = (editorSDK: FlowEditorSDK) => {
  return async () => {
    const isDynamicPage = !!(await editorSDK.routers.getCurrentDynamicRouting(
      '',
    ));
    if (isDynamicPage) {
      await editorSDK.editor.routers.refresh('');
    }
    await editorSDK.document.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: '',
    });
    await editorSDK.document.application.reloadManifest();
  };
};

export const openDashboardPanel = async (editorSDK: FlowEditorSDK, url) => {
  await editorSDK.editor
    .openDashboardPanel('', {
      url,
      closeOtherPanels: true,
    })
    .then(refreshAppServices(editorSDK));
};

export const openDashboardProjects = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/projects');
};

export const openDashboardNewProject = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/project');
};

export const openDashboardCollections = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(
    editorSDK,
    '/portfolio/collections?navToCollectionIfNeeded=true',
  );
};

export const openDashboardNewCollection = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/collection');
};
